#digital-marketing-services {
    background-color: #f5f5f5;
    padding: 30px 0;

    .services-txt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .digital-marketing-services-spacing {
        padding-top: 30px;

        .main-head {
            padding-right: 30px;
        }

        .icon-box {
            background-color: #fff;
            border-radius: 20px;
            border: 1px solid #e6e6e6;
            padding: 30px 20px 40px 35px;
            height: 350px;
            margin-bottom: 30px;

            :hover {
                background-color: #002E5B;
            }

            .icon-box-head {
                padding-top: 15px;
            }

            img {
                width: 70px;
            }

            ul {
                padding-left: 0;

                li {
                    font-size: 15px;
                    font-family: "PlusJakartaSans-Medium";
                    padding-bottom: 5px;
                }
            }
        }
    }
}

#digital-marketing-services .icon-box:hover {
    background-color: #002E5B;
}

#digital-marketing-services .icon-box:hover h3 {
    color: #fff;
}

#digital-marketing-services .icon-box:hover li {
    color: #fff;
}


#how-it-works {
    .img {
        padding-right: 30px;

        img {
            height: 500px;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .main-head {
        font-size: 40px;
        line-height: 3.3rem;
    }

    .text-desciption {
        text-align: justify;
        font-size: 15px;
    }

    .work-list-icons {
        padding-top: 25px;

        .work-list {
            padding-bottom: 20px;

            span {
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                }

                p {
                    font-weight: 600;
                    padding-left: 10px;
                    font-family: PlusJakartaSans-Medium;
                }
            }
        }
    }
}


// workProcess
#workProcess {
    background-image: url(../../Assests/Img/BlueBg.webp);
    padding: 40px 0 70px 0;

    .left-box {
        padding-right: 30px;
    }

    .middle-box {
        padding: 0 15px;
    }

    .right-box {
        padding-left: 30px;
    }

    .main-head,
    .text-desc {
        color: #fff;
    }

    .services-txt {
        display: flex;
        align-items: flex-end;
    }

    .display-flex {
        padding-top: 40px;

        .steps-box {
            background-color: #2B529D;
            padding: 40px 30px;
            border-radius: 30px;
            color: #fff;
            height: 330px;
            border: 1px solid #fff;

            .count {
                width: 50px;
                background: #fff;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                span {
                    color: #000;
                    background-color: #fff;
                    font-size: 22px;
                    font-weight: bold;
                    font-family: PlusJakartaSans-ExtraBold;
                }
            }

            h4 {
                font-size: 23px;
                padding-top: 40px;
                padding-bottom: 9px;
                text-transform: capitalize;


            }

            p {
                font-family: PlusJakartaSans-Light;
                line-height: 1.7;
                font-size: 15px;
            }
        }
    }
}


// advantages
#advantages {
    img {
        height: 590px;
        object-fit: cover;
        border-radius: 10px;
    }

    .top-advantage-spacing {
        padding-top: 20px;

        .icon-box {
            border: 1px solid #FD0002;
            padding: 15px 20px 20px 20px;
            border-radius: 8px;
            margin-bottom: 25px;

            .number {
                font-size: 18px;
                color: #FD0002;
                font-weight: 700;
                padding-bottom: 7px;
            }

            h3 {
                font-size: 20px;
                font-family: PlusJakartaSans-Bold;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

// let-me-things
.let-me-things {
    .digital-dreams-inner {
        text-align: center;

        .btn {
            margin-top: 10px;
            display: flex;
            justify-content: center;
        }
    }
}


// accordionExample
#accordionExample {
    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url(../../Assests/Img/minus.svg);
    }

    .accordion-button::after {
        background-image: url(../../Assests/Img/add.svg);
    }

    .accordion-item {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        .accordion-button {
            padding: 30px 0;
            font-family: PlusJakartaSans-Bold;


        }

        .accordion-body {
            padding: 0 65px 20px 0;

            p {
                font-size: 14px;
                text-align: justify;
            }
        }

        .accordion-button:not(.collapsed) {
            background-color: transparent;
            box-shadow: none;
            padding-bottom: 20px;
            color: #FD0002;
        }
    }
}

// discussion
#digitalMarketing .discussion {
    background-color: #000;
    background: #000;

    button {
        background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%) !important;
    }

    .main-head {
        line-height: 2.7rem;
        padding-right: 20px;
    }
}



@media only screen and (max-width: 600px) {
    #about-banner {
        padding-bottom: 0;
    }

    #digitalMarketing {

        .text-desc {
            margin-bottom: 30px !important;
        }

        #digital-marketing-services {
            padding-top: 30px;

            .digital-marketing-services-spacing {
                padding-top: 0px;

                .icon-box {
                    margin-bottom: 30px;
                }
            }
        }
    }

    #how-it-works {
        .img {
            padding-right: 10px;
            padding-top: 30px;
            padding-bottom: 30px;

            img {
                height: auto;
            }
        }

        .main-head {
            font-size: 35px;
            line-height: 3rem;
        }
    }

    #workProcess {
        .left-box {
            padding-right: 12px;
        }

        .right-box {
            padding-left: 12px;
        }

        .middle-box {
            padding: 0 12px;
        }

        .display-flex {
            padding-top: 0px;

            .steps-box {
                margin-bottom: 30px;
            }
        }
    }

    #advantages {
        img {
            height: auto;
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }

    .help-and-support {
        img {
            margin-top: 30px !important;
        }

        #accordionExample {
            .accordion-item {
                .accordion-body {
                    padding: 0 30px 20px 0;
                }
            }
        }
    }

    .top-spacing.let-me-things,
    .top-spacing.discussion {
        margin-top: 30px;
    }

}


@media only screen and (min-width: 600px) and (max-width:992px) {

    // about-banner 
    #digitalMarketing {
        #about-banner {
            .main-head {
                line-height: 3.1rem;
            }
        }
    }


    // digital-marketing
    #digital-marketing-services {
        .services-txt {
            align-items: start;
            margin-bottom: 30px;
        }

        .digital-marketing-services-spacing {
            padding-top: 0px;

            .icon-box {
                margin-bottom: 30px;
            }
        }
    }


    // how-it-works
    #how-it-works {
        .img {
            padding-bottom: 30px;
        }
    }


    // workProcess
    #workProcess {
        .left-box {
            padding-right: 10px;
        }

        .right-box {
            padding-left: 10px;
        }

        .middle-box {
            padding: 0 10px;
        }

        .display-flex {
            padding-top: 0px;

            .steps-box {
                margin-top: 30px;
            }
        }
    }

    // advantages
    #advantages {
        img {
            height: 400px;
            margin-bottom: 30px;
        }

        .top-advantage-spacing {
            .icon-box {
                height: 188px;
            }
        }
    }

    // help-and-support
    .help-and-support {
        img {
            margin-top: 30px;
        }
    }

    #digital-dreams {
        .digital-dreams-inner {
            .main-head {
                font-size: 27px;
                line-height: 2.4rem;
                padding-right: 0px;
            }
        }
    }

}