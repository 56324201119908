// ------------------------
#get-in-touch {
    .get-in-touch-inner {
        padding-top: 20px;

        .black-box {
            background-color: #000;
            padding: 40px 30px 60px 30px;
            color: #fff;
            border-radius: 15px;
            background-image: url(../Assests/Img/Form.webp);

            h5 {
                text-transform: capitalize;
                font-family: PlusJakartaSans-Medium;

                img {
                    width: 36px;
                    padding-right: 10px;
                }
            }

            p {
                a {
                    font-family: PlusJakartaSans-Light;
                    color: #fff;
                    font-size: 14px;
                }
            }

            .for-top-spacing {
                padding-top: 40px;
            }

            .for-icon-top-spacing {
                padding-top: 50px;

                .social-icons {
                    a {
                        color: #fff;

                        svg {
                            font-size: 20px;
                            margin-right: 25px;
                        }
                    }
                }

            }
        }

        .contact-form {
            display: flex;
            align-items: center;

            form {
                width: 100%;
                padding-left: 40px;

                .top-line-flex {
                    padding-top: 35px;

                    .input-flex {
                        label {
                            text-transform: capitalize;
                            font-size: 14px;
                        }

                        .radio-input {
                            padding-top: 10px;

                            label {
                                font-size: 14px;
                            }
                        }
                    }

                    .input-flex {
                        .radio-input {
                            display: flex;
                            justify-content: space-between;

                            label {
                                padding-left: 10px;
                            }
                        }
                    }

                }

                .line-flex {
                    display: flex;
                    justify-content: space-between;
                    gap: 30px;

                    .input-flex {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        label {
                            text-transform: capitalize;
                            font-size: 14px;
                        }

                        input,
                        textarea {
                            width: 100%;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid #0000003d;
                            padding: 2px;
                        }

                        textarea {
                            height: 100px;
                            padding-top: 10px;

                            ::placeholder {
                                text-transform: capitalize;
                            }
                        }
                    }
                }

                .btn {
                    padding-top: 30px !important;
                }
            }
        }
    }
}

// ------------------------
#testimonial {

    .testimonial {
        padding-top: 20px;

        button.carousel-control-next,
        button.carousel-control-prev {
            opacity: 1;
        }

        button.carousel-control-prev {
            margin-left: -40px;
        }

        button.carousel-control-next {
            margin-right: -40px;
        }

        .prev-icon,
        .next-icon {
            background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 85px;
            height: 85px;
            border-radius: 50%;
            border: 10px solid #fff;

            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                background-image: none;
                width: auto;
                height: auto;

                svg {
                    font-size: 29px;
                }
            }
        }

        .carousel-inner {
            padding: 0 40px;

            .carousel-item {
                background-color: #f5f5f5;
                border-radius: 15px;
                padding: 60px;

                .test-description {
                    padding: 0 40px 0 60px;

                    .text-desciption {
                        font-size: 17px;
                        font-weight: 500;
                        padding: 20px 0;
                    }

                    h3 {
                        text-transform: capitalize;
                        font-size: 24px;
                    }

                    h6 {
                        text-transform: capitalize;
                    }
                }

                .row {
                    display: flex;
                    align-items: center;
                }

                .test-img {
                    .testi-icon {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: -40px;

                        span {
                            border-radius: 50%;
                            width: 60px;
                            height: 60px;
                            background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 6px solid #f5f5f5;
                            z-index: 9;

                            svg {
                                color: #fff;
                                font-size: 22px;
                            }
                        }
                    }

                    img {
                        width: 250px;
                        height: 250px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-left: 50px;
                    }


                }

                .yellow-star-flex {
                    display: flex;
                    align-items: center;

                    h6 {
                        margin: 0;
                        padding-left: 10px;
                    }

                    .yellow-star {
                        svg {
                            color: #f9d301;
                            font-size: 37px;
                        }
                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 600px) {
    section#get-in-touch {
        padding-left: 0px;
        padding-right: 0px;

        .get-in-touch-inner {
            .contact-form form {
                padding-left: 10px;
                padding-top: 40px;

                .top-line-flex {
                    .input-flex {
                        .radio-input {
                            display: grid;
                        }
                    }
                }
            }
        }
    }

    // -----------------
    #testimonial {
        padding-left: 15px;
        padding-right: 15px;

        .testimonial-main {
            padding: 0;
            margin: 0;

            .testimonial {
                button.carousel-control-next {
                    margin-right: 0px;
                }

                button.carousel-control-prev {
                    margin-left: 0px;
                }

                .next-icon,
                .prev-icon {
                    height: 52px;
                }

                .carousel-inner {
                    padding: 0 0px;

                    .carousel-item {
                        padding: 30px;

                        .test-img {
                            text-align: center;

                            .testi-icon {
                                margin-bottom: -50px;
                                display: none;
                            }
                        }

                        .yellow-star-flex {
                            justify-content: center;
                            padding-top: 10px;

                            .yellow-star svg {
                                color: #f9d301;
                                font-size: 22px;
                            }
                        }

                        .test-description {
                            text-align: center;
                            padding: 0px 20px;
                        }

                        .test-img {
                            img {
                                margin-left: 0px;
                                width: 150px;
                                height: 150px;
                            }
                        }
                    }
                }

                .next-icon .carousel-control-next-icon svg,
                .prev-icon .carousel-control-prev-icon svg {
                    font-size: 22px;
                }

                .next-icon,
                .prev-icon {
                    width: 75px;
                    height: 55px;
                    border: 5px solid #fff;
                }

            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width:992px) {
    #get-in-touch {
        .col-md-12 {
            padding: 0;
        }

        .get-in-touch-inner {
            .contact-form {
                form {
                    padding-left: 10px;
                    padding-top: 30px;
                }
            }
        }
    }

    // ---------------------
    #testimonial {
        .row {
            padding: 0;
        }

        .testimonial {
            padding: 0;

            .next-icon,
            .prev-icon {
                width: 65px;
                height: 65px;
            }

            .carousel-inner {
                padding: 0 0px;

                .carousel-item {
                    margin-top: 30px;
                    padding: 30px;

                    .test-description {
                        padding: 0 40px 0 30px;

                        .text-desciption {
                            font-size: 16px;
                        }
                    }

                    .test-img img {
                        width: 200px;
                        height: 200px;
                        margin-left: 0px;
                    }
                }
            }

            button.carousel-control-next {
                margin-right: -30px;
            }

            button.carousel-control-prev {
                margin-left: -30px;
            }
        }
    }
}