#about-guide {
    .about-guide-top-spacing {
        padding-top: 20px;

        .icon-box {
            background-color: #f5f5f5;
            border: 1px solid #e6e6e6;
            border-radius: 30px;
            padding: 34px 26px 65px 26px;

            div {
                background-color: #002E5B;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                svg {
                    color: #fff;
                    font-size: 28px;
                }
            }

            .text-desciption {
                font-size: 15px;
                font-family: 'PlusJakartaSans-Medium';
            }
        }

        .icon-box1 {
            margin-right: 20px;
        }

        .icon-box2 {
            margin-right: 10px;
            margin-left: 10px;
        }

        .icon-box3 {
            margin-left: 20px;
        }
    }
}


@media only screen and (max-width: 600px) {
    #about-guide {
        .about-guide-top-spacing {
            .icon-box1 {
                margin-right: 0px;
            }

            .icon-box2 {
                margin-right: 0px;
                margin-left: 0px;
                margin-top: 30px;
            }

            .icon-box3 {
                margin-left: 0px;
                margin-top: 30px;
            }
        }
    }

  

    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 600px) and (max-width:992px) {
    #about-guide {
        .about-guide-top-spacing {
            .icon-box1 {
                margin-right: 0px;
            }

            .icon-box2 {
                margin-right: 0px;
                margin-left: 0px;
                margin-top: 30px;
            }

            .icon-box3 {
                margin-left: 0px;
                margin-top: 30px;
            }
        }
    }
}