// LOGOS
div#logos {
    padding: 0px 0 40px 0;
    display: flex;
    justify-content: space-between;

    .logosImg {
        display: flex;
        justify-content: flex-end;

        a {
            display: flex;
            justify-content: flex-end;
        }

        img {
            width: 80%;
            object-fit: contain;
        }
    }

}

// OWNER
#about {
    .img {
        padding-right: 20px;

        img {
            height: 500px;
        }
    }

    .text-desciption {
        font-size: 16px;
    }
}

// GUIDING
#guiding {
    .guiding-spacing {
        padding-top: 30px;

        .left-side {
            background-color: #f5f5f5;
            padding: 30px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            height: 270px;
            border: 1px solid #ccc;

            h2 {
                font-weight: 900;
                font-size: 22px;
            }

            .text-desciption {
                font-size: 15px;
                text-align: justify;

            }
        }


        .right-side {
            // background-image: url(../../Assests/Img/Mission.webp);
            // border-radius: 30px;

            img {
                border-radius: 30px;
                height: 270px;
                object-fit: cover;
            }
        }
    }
}

// JOURNEY
#journey {
    background-image: url(../../Assests/Img/BlueBg.webp);
    color: #fff;
    padding: 50px 0;

    .journey-flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 50px;

        .row1 {
            width: 20%;
            padding-left: 20px;

            img {
                height: 230px;
                object-fit: cover;
                padding-right: 60px;
                width: 93%;
            }

            .borders {
                border-left: 1px solid #fff;
                height: 35px;
                margin-top: 10px;
            }

            h3 {
                font-size: 20px;
                font-weight: 700;
            }

            .text-desciption {
                font-size: 14px;
            }
        }

        .rowReverse {
            img {
                padding-top: 10px;
            }
        }
    }
}

// TEAM-MEMBER
#team-member {
    .team-member-all-box {
        padding-top: 30px;

        .team-member-box1 {
            padding-right: 25px;
        }

        .team-member-box2 {
            padding: 0 17.5px;
        }

        .team-member-box3 {
            padding: 0 17.5px;
        }

        .team-member-box4 {
            padding-left: 25px;
        }

        .team-member-box {
            background-color: #000;
            color: #fff;
            padding: 15px;
            border-radius: 18px;

            img {
                border-radius: 15px;
                height: 200px;
                object-fit: cover;
            }

            .team-member-content {
                padding-top: 15px;
                padding-bottom: 15px;

                h3 {
                    font-size: 19px;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                }

                h6 {
                    font-size: 11px;
                    text-transform: capitalize;
                }

                .text-desciption {
                    font-size: 14px;
                }
            }


        }
    }

}

// RESPOSSIVNESS
@media only screen and (max-width: 600px) {

    // LOGOS
    div#logos {
        flex-direction: column;
        padding: 0 10px 20px 10px;

        .logosImg,
        .logosImg1 {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;

            img {
                width: 250px;
            }
        }
    }

    // GUIDING
    section#guiding {
        .guiding-spacing1 {
            flex-direction: column-reverse;
        }

        .guiding-spacing {
            padding-left: 15px;
            padding-right: 15px;

            .left-side {
                height: auto;
            }

            .right-side {
                padding: 0;
                padding: 0;

                img {
                    margin-bottom: 20px;
                }
            }
        }
    }

    // JOURNEY
    #journey {
        padding-left: 5px;
        padding-right: 5px;

        .journey-flex {
            display: block;
            padding-top: 20px;

            .row1 {
                width: 100%;
                padding-left: 0;
                padding-bottom: 40px;

                img {
                    width: 100%;
                    padding-right: 0px;
                }
            }
        }
    }

    // TEAM-MEMBER
    #team-member {
        .team-member-all-box {
            .team-member-box1 {
                padding: 0 15px;
                padding-bottom: 20px;
            }

            .team-member-box2 {
                padding: 0 15px;
                padding-bottom: 20px;
            }

            .team-member-box3 {
                padding: 0 15px;
                padding-bottom: 20px;
            }

            .team-member-box4 {
                padding: 0 15px;
            }
        }
    }




}

@media only screen and (min-width: 600px) and (max-width:992px) {
    div#logos {
        display: ruby;

        .logosImg1,
        .logosImg {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;

            img {
                width: 70%;
            }
        }
    }

    #guiding {
        .guiding-spacing1 {
            flex-direction: column;
        }

        .guiding-spacing {
            .left-side {
                height: auto;
                margin-top: 20px;
            }

            .right-side {
                padding: 0;

                img {
                    height: auto;
                    margin-top: 20px;
                }
            }
        }
    }

    #journey {
        .journey-flex {
            .row1 {
                h3 {
                    font-size: 16px;
                }

                img {
                    padding-right: 0px;
                    width: 100%;
                }
            }
        }
    }

    #team-member {
        .team-member-all-box {

            .team-member-box1,
            .team-member-box3 {
                padding-right: 15px;
                padding-left: 0;
            }

            .team-member-box3 {
                padding-top: 20px;
            }



            .team-member-box2,
            .team-member-box4 {
                padding: 0 0px 0 15px;
            }

            .team-member-box4 {
                padding-top: 20px;
            }
        }
    }
}