footer {
    background-image: url(../../Assests/Img/BlueBg.webp);
    padding: 120px 0 30px 0;
    color: #fff;

    .newsletter {
        .main-head {
            font-size: 33px;
            line-height: 2.8rem;
        }

        p {
            font-family: PlusJakartaSans-Light;
        }

        .input {
            display: flex;
            margin-right: 65px;
            background-color: #fff;
            justify-content: center;
            align-items: center;
            height: 45px;
            border-radius: 25px;
            margin-top: 10px;
            margin-bottom: 10px;

            input {
                width: 100%;
                border: none;
                outline: none;
                height: 45px;
                padding: 0 20px;
                border-radius: 25px;
                font-size: 14px;

            }

            button {
                border: none;
                outline: none;
                height: 40px;
                width: 50px;
                margin-right: 3px;
                border-radius: 50%;
                background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                color: #fff;
                font-size: 20px;
            }
        }

        .text-desciption {
            font-size: 14px;
        }

        .social-icons {
            padding-top: 25px;

            a {
                color: #fff;

                svg {
                    font-size: 20px;
                    margin-right: 35px;
                }
            }
        }
    }

    .all-links {
        h4 {
            font-size: 24px;
            text-transform: capitalize;
            font-family: PlusJakartaSans-Bold;
        }

        ul {
            padding-left: 0rem;
            padding-top: 15px;

            li {
                text-transform: capitalize;
                font-family: PlusJakartaSans-Light;
                font-size: 14px;
                padding-bottom: 14px;
                a {
                    font-family: PlusJakartaSans-Light;
                    font-size: 14px;
                    padding-bottom: 14px;
                    color: #fff;
                }
            }
        }

        .all-links-icon {
            display: flex;
            padding-bottom: 15px;

            svg {
                font-size: 22px;
                padding-top: 3px;
            }

            ul {
                padding-top: 0px;
                padding-left: 15px;

                li {
                    padding-bottom: 0;
                    text-transform: inherit;

                    a {
                        color: #fff;
                        font-family: PlusJakartaSans-Light;

                    }
                }
            }
        }
    }

    .imp-links {
        display: flex;
        align-items: center;
        gap: 50px;

        img {
            width: 45%;

        }

        img.icon2 {
            width: 22% !important;
        }


    }

    .crafting {
        padding-top: 50px;

    }

    .copyright {
        p {
            font-size: 14px;
            text-transform: capitalize;

            a {
                color: #fff;
            }
        }

        .copyright-txt {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: 600px) {
    footer {
        padding: 50px 10px;

        .newsletter {
            padding-bottom: 30px;
        }

        .all-links {
            h4 {
                padding-bottom: 15px;
            }

            padding-bottom: 30px;

            ul {
                padding-top: 0px;
            }
        }

        .copyright {
            p {
                text-align: center;
            }

            .copyright-txt {
                padding-top: 10px;
            }
        }

        .crafting {
            padding-top: 10px;
        }

    }
}

@media only screen and (min-width: 600px) and (max-width:992px) {
    #footer {
        .all-links {
            padding-top: 30px;
        }

        .padding-remove {
            padding-top: 0px;
        }
    }
}