#portfolio-tab {
    .nav-tabs {
        background-color: #000;
        color: #fff;
        padding: 40px;
        border-radius: 19px;
        display: flex;
        justify-content: space-between;

        li.nav-item {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 16px;
                letter-spacing: 0.045rem;
                padding-top: 12px;
                padding-bottom: 12px;
                border: none;
                width: 240px;

                &:hover {
                    background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                    border: none;
                    border-radius: 22px;

                }
            }


        }

        .nav-link.active {
            background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
            border: none;
            border-radius: 22px;
        }

    }

    #custom-tabs {
        padding-top: 50px;

        .portfolio-extra-spacing {
            padding-bottom: 0px;
        }

        .portfolio-btn {
            display: none;
        }

        .main-head {
            font-size: 35px;
        }

        .tab-content {
            #portfolio {
                margin-top: 10px;
            }
        }

        .tablist-bottom {
            display: flex;
            align-items: center;
            // padding-bottom: 19px;
            background-color: #000;
            border-radius: 17px;

            .nav-tabs {
                padding: 0;
                background-color: transparent;
                border-bottom: 0px;
                width: 100%;
                gap: 20px;

                .nav-item {
                    width: 30%;
                }

                .nav-link.active {
                    color: #fff;
                }

                .nav-link:hover {
                    color: #fff;
                }

                .nav-item {
                    a {
                        width: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 15px;
                        color: #000;
                        border-radius: 30px;
                        background-color: #fff;
                    }
                }
            }


        }
    }
}