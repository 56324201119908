body.nav-open {
    // background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.5s;
    overflow: hidden;
}

body.nav-open #navbar {
    position: relative;
    z-index: 1;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 3; // Updated z-index for the sidebar
        top: 0;
        right: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;

        a {
            padding: 8px 8px 8px 32px;
            text-decoration: none;
            font-size: 25px;
            color: #fff;
            display: block;
            transition: 0.3s;
        }

        .closebtn {
            position: absolute;
            top: 0;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;
        }
    }

    .logo img {
        width: 220px;
    }

    .navLinks ul {
        display: flex;
        margin-bottom: 0;

        li a {
            padding-left: 10px;
            text-transform: uppercase;
            font-size: 13px;
            font-family: PlusJakartaSans-Bold;
            color: #000;
        }
    }

    .navbar-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        span.hamburger {
            padding-left: 25px;
        }
    }

    .open {
        width: 550px !important;
    }

    .overlay {
        position: fixed;
        z-index: 2; // Lower than sidebar but above content
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: background-color 0.5s;
    }

    span.hamburger:focus-visible{
        outline: none;
    }
}

@media screen and (max-height: 450px) {
    #navbar .sidenav {
        padding-top: 15px;
    }

    #navbar .sidenav a {
        font-size: 18px;
    }
}

@media screen and (max-width: 992px) {

    #navbar .navbar-btn .btn,
    .navLinks {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    #navbar {
        .open {
            width: 90% !important;
        }

        .sidenav a {
            font-size: 20px;
            color: #fff;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
    #navbar {
        .sidenav a {
            font-size: 20px;
            color: #fff;
        }
    }
}