$primary-red: #FD0002;
$secondary-red: #9A0000;
$primary-Blue: #173163;
$secondary-Blue: #1048B4;
$linear-gradient-red: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
$light-bg: #f5f5f5;
$light-border: #e6e6e6;
$white: #fff;

#banner-home {
    padding: 30px 0;

    .top-banner-home {
        padding-bottom: 30px;
    }

    h1 {
        font-size: 55px;
        font-family: PlusJakartaSans-Bold;


    }

    .heading-space {
        padding-left: 100px;
    }

    .talk-now-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 13px;

        .talk-now {
            display: flex;
            align-items: center;
            justify-content: center;

            .talk-now-btn-icon {
                margin-top: -10px;
                background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                outline: 2px solid $secondary-red;
                outline-offset: 3px;

                svg {
                    color: #fff;
                }
            }

            h2 {
                font-size: 16px;
                font-weight: 600;
                background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                margin-left: 10px;
            }
        }
    }

    .top-banner-left {

        p {
            font-size: 18px;
            line-height: 1.8rem;
            font-family: 'PlusJakartaSans-bold';
        }

        .Trusted {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 25px;

            img {
                width: 150px;

            }

            p {
                font-size: 16px;
                line-height: 1.32rem;
                padding-left: 20px;
            }
        }

        .counting-box {
            padding: 15px;
            border-radius: 10px;
            color: #fff;

            h3 {
                font-size: 15px;
            }
        }

        .red-box {
            background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);

        }

        .blue-box {
            background: linear-gradient(90deg, rgba(23, 49, 99, 1) 0%, rgba(16, 72, 180, 1) 59%);
        }

        .top-spacing {
            padding-top: 30px;
        }
    }

    .BannerHomeImg {
        img {
            border-radius: 13px;
            height: 262px;
            object-fit: cover;
        }
    }

}

// ------------------
#services {
    .services-txt {
        display: flex;
        align-items: center;
    }

    .main-head {
        padding-right: 150px;
    }

    .all-services-box {
        margin-top: 55px;
        margin-bottom: 40px;

        .services-box {
            background-color: #f5f5f5;
            border: 1px solid #e6e6e6;
            border-radius: 10px;
            padding: 34px;
            margin-bottom: 30px;

            h3 {
                font-weight: 600;
                font-size: 28px;
            }

            .btn-grp {
                margin-top: 20px;

                button {
                    border: 1px solid $light-border;
                    padding: 5px;
                    border-radius: 30px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    padding: 8px 20px;
                    font-size: 13px;
                    background-color: $white;

                    &:hover {
                        background-color: #e6e6e675;
                    }
                }
            }


        }
    }
}

// -------------------
#about {
    background-image: url(../../Assests/Img/BlueBg.webp);
    padding: 80px 0;

    .img {
        padding-right: 40px;

        img {
            height: 460px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .about-text-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1,
        p {
            color: $white;
        }
    }

    .text-desciption {
        padding: 10px 0;
    }

    .sign {
        display: flex;
        gap: 40px;
        padding-top: 15px;

        img {
            width: 60%;
        }
    }
}

// ------------------
.small-head {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
}

#portfolio {
    margin-bottom: 30px;

    .portfolio-black-box-top-spacing {
        padding-top: 20px;
    }

    .portfolio-black-box {
        background-color: #202020;
        color: #fff;
        padding: 30px;
        border-radius: 20px;



        p {
            font-weight: 300;
            font-size: 15px;
        }

        .developing {
            padding-top: 40px;
        }

        .left-text {
            padding-top: 20px;
            padding-right: 30px;

            .left-line {
                border-right: 2px solid #ffffff57;
                padding-right: 20px;

            }

            img {
                width: 130px;
                padding-top: 20px;
            }
        }

        .DrRao {
            background-image: url(../../Assests/Img/dr-rao.webp);
            border-radius: 17px;
        }

        .jademaley {
            background-image: url(../../Assests/Img/jademaley.webp);
        }

        .ShamisTate {
            background-image: url(../../Assests/Img/ShamisTate.webp);
        }

        .accent-roofing {
            background-image: url(../../Assests/Img/accent-roofing.webp);
        }

        .lifeUpCorporateWellnesMain {
            background-image: url(../../Assests/Img/lifeupcorporatewellnessMain.webp);
        }

        .LeapWideMain {
            background-image: url(../../Assests/Img/LeapWideMain.webp);
        }

        .lucknowiandaazMain {
            background-image: url(../../Assests/Img/lucknowiandaazMain.webp);
        }
    }

    .portfolio-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 35px;

        h3 {
            font-family: PlusJakartaSans-ExtraBold;
            font-size: 25px;
            padding-bottom: 5px;
            margin-bottom: 0;
        }

        .text-desciption {
            text-align: center;
            padding-bottom: 10px;
            font-size: 14px;
        }
    }

    .portfolio-extra-spacing {
        padding-top: 60px;
        padding-bottom: 40px;

        .portfolio-right {
            padding-right: 35px;
            padding-left: 0;
        }
    }
}

// -----------------------
#steps {
    background-image: url(../../Assests/Img/BlueBg.webp);
    padding: 30px 0 70px 0;

    .left-box {
        padding-right: 30px;
    }

    .middle-box {
        padding: 0 15px;
    }

    .right-box {
        padding-left: 30px;
    }

    .main-head {
        color: #fff;
        text-align: center;
        line-height: 3.5rem;
        font-size: 40px;
    }

    .steps-box {
        background-color: #2B529D;
        padding: 40px 30px;
        border-radius: 30px;
        color: #fff;
        height: 330px;
        border: 1px solid #fff;

        .count {
            width: 50px;
            background: #fff;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            span {
                color: #000;
                background-color: #fff;
                font-size: 22px;
                font-weight: bold;
                font-family: PlusJakartaSans-ExtraBold;
            }

        }

        h4 {
            font-size: 26px;
            font-weight: 600;
            padding-top: 40px;
            padding-bottom: 9px;
        }

        p {
            font-family: PlusJakartaSans-Light;
            line-height: 1.7;
        }


    }

    .display-flex {
        padding-top: 50px;
    }
}

// ---------------------------
#digital-dreams {
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
    color: #fff;
    padding: 28px 30px 25px 30px;

    .digital-dreams-inner {
        display: flex;
        align-items: center;
        align-content: center;

        .img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60%;
            }
        }

    }

    .main-head {
        font-size: 33px;
        line-height: 2.5rem;
    }

    .text-desciption {
        font-size: 15px;
    }

    .btn {
        margin-top: 10px;
        cursor: auto;

        &:active {
            outline: none;
            border: none;
        }

        button {
            background: #000 !important;
        }
    }
}

// -----------------------
#industries {
    background-image: url(../../Assests/Img/BlueBg.webp);
    padding: 50px 0 100px 0;
    color: #fff;
    margin-top: 40px;

    .all-industries-box {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;

        .industries-box {
            .box {
                background-color: #1c50b3;
                height: 130px;
                width: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ffffff73;
                border-radius: 10px;

                img {
                    width: 50px;
                }
            }

            p {
                font-size: 14px;
                text-align: center;
                text-transform: capitalize;
                padding-top: 5px;
            }
        }
    }

}

// -----------------------
.suceed {
    margin-bottom: -70px;
    // z-index: 9999;
    position: relative;
    margin-top: 60px;
}


.mobile-show {
    display: none !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main-head {
        font-size: 30px;
        line-height: 2.5rem;
    }

    .small-head {
        font-size: 20px;
    }

    #industries {
        .all-industries-box {
            flex-direction: column;
        }
    }

    #banner-home {
        .heading-space {
            padding-left: 0px;
        }

        h1 {
            font-size: 40px;
        }

        .top-banner-left {
            p {
                font-size: 18px;
            }
        }

        .top-banner-home {
            padding-bottom: 10px;
        }

        .top-banner-left {
            .Trusted {
                img {
                    width: 110px;
                }

                p {
                    font-size: 14px;
                }
            }

            .red-box {
                margin-bottom: 20px;
            }

            .blue-box {
                margin-bottom: 20px;
            }
        }
    }


    #services {
        .main-head {
            padding-right: 0px;
        }

        .text-desc {
            padding-top: 10px;
        }

        .text-desc::before {
            top: 61%;
        }

        .all-services-box {
            padding: 0 25px;

            .services-box {
                padding: 24px 14px;

                h3 {
                    font-size: 22px;
                }

                .btn {
                    padding-top: 20px !important;
                }
            }
        }

        .btn button:hover {
            flex-direction: row-reverse;
        }
    }

    #about {
        padding: 50px 15px;


        .row {
            flex-direction: column-reverse;

            .about-text-desc {
                padding: 0;

                .sign {
                    flex-direction: column;
                    gap: 20px;

                    img {
                        width: 44%;
                    }
                }
            }

            .img {
                padding-right: 0px;
                padding-left: 0;
                padding-top: 20px;

                img {
                    height: 338px;
                }
            }
        }


    }

    #portfolio {
        padding-left: 15px;
        padding-right: 15px;

        .portfolio-extra-spacing {
            padding-top: 20px;
        }

        .row-reverse {
            flex-direction: column-reverse;
        }

        .portfolio-black-box-top-spacing {
            border: 1px solid #ccc;
            border-radius: 20px;
            padding-bottom: 20px;
            margin-top: 20px;

            .portfolio-right {
                padding-left: 20px;
                padding-right: 20px;
                justify-content: initial;
                align-items: start;

                h3 {
                    font-size: 22px;
                }

                .text-desciption {
                    text-align: left;
                }
            }
        }

        .portfolio-black-box {
            padding-top: 40px;
            margin-top: 20px;

            .DrRao {
                width: 100%;
                height: 290px;
                margin-top: 20px;
            }

            .left-text {
                padding-right: 0px;
                padding-top: 0;
                padding-left: 0;

                .left-line {
                    border-right: 0;
                    padding-right: 0px;
                }
            }
        }
    }

    #steps {
        .main-head {
            font-size: 35px;
            line-height: 3.2rem;
        }

        .display-flex {
            padding-top: 20px;

            .steps-box {
                margin-bottom: 20px;
            }

            .left-box {
                padding-right: 12px;
            }

            .middle-box {
                padding: 0 12px;
            }

            .right-box {
                padding: 0 12px;
            }
        }

        .mobile-dispaly-flex {
            padding-top: 0;
        }
    }


    #digital-dreams {
        border-radius: 0px;
        padding: 28px 15px 25px 15px;

        .digital-dreams-inner {
            text-align: center;

            .img {
                img {
                    width: 30%;
                    padding: 10px 0px;
                }
            }
        }
    }

    #industries {
        padding: 50px 5px 50px 5px;

        .desktop-show {
            display: none !important;
        }

        .mobile-show {
            display: block !important;
        }

        .all-industries-box {
            align-items: center;

            .row {
                justify-content: space-between;

                .industries-box {
                    padding-bottom: 35px;
                    width: auto;

                    .box {
                        height: 140px;
                        width: 140px;
                    }
                }
            }

        }
    }

    .suceed {
        margin-bottom: 0px;

        .text-desciption {
            padding: 10px 0;
        }
    }

    #get-in-touch {
        .get-in-touch-inner {
            .contact-form {
                form {
                    .line-flex {
                        display: block;

                        .input-flex {
                            padding-bottom: 20px;
                        }
                    }

                    .top-line-flex {
                        padding-top: 0px;
                        padding-bottom: 20px;
                    }

                    .btn {
                        padding-top: 0px !important;
                    }
                }
            }
        }
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width:992px) {
    #banner-home {
        .heading-space {
            padding-left: 0px;
        }

        .BannerHomeImg {
            img {
                margin-top: 40px;
            }
        }

        .top-banner-left {
            .Trusted {
                justify-content: inherit;
            }
        }

        .talk-now-btn {
            padding-bottom: 0px;
            padding-top: 20px;

            .talk-now {
                justify-content: end;
            }
        }



    }

    #services {
        .services-txt {
            margin-top: 15px;
        }

        .all-services-box {
            margin-top: 35px;

            .services-box {
                h3 {
                    padding: 5px 0;
                }

                .btn {
                    margin-top: 20px;
                }
            }
        }
    }

    #about {
        padding: 50px 0;

        .row {
            flex-direction: column-reverse;

            .img {
                padding-right: 0px;
                padding-top: 30px;
            }
        }
    }

    #portfolio {
        .row-reverse {
            flex-direction: column-reverse;
        }

        .portfolio-black-box-top-spacing {
            border: 1px solid #ccc;
            margin-bottom: 30px;
            border-radius: 10px;
            padding: 20px 8px;

            .portfolio-black-box {
                margin-top: 20px;
            }

            .portfolio-right {
                justify-content: left;
                align-items: start;
                padding-left: 25px;

                h3 {
                    font-size: 24px;
                }
            }
        }
    }

    #steps {
        padding: 50px 0 50px 0;

        .main-head {
            padding-bottom: 20px;
        }

        .steps-box {
            margin-bottom: 30px;
        }

        .left-box {
            padding-right: 0px;
            padding: 0;
        }

        .middle-box {
            padding: 0 0px;
        }

        .right-box {
            padding: 0px;
        }

        .display-flex {
            padding-top: 0px;
        }
    }

    #testimonial {
        .row {
            padding: 0;
        }

        .testimonial {
            padding: 0;

            .next-icon,
            .prev-icon {
                width: 65px;
                height: 65px;
            }

            .carousel-inner {
                padding: 0 0px;

                .carousel-item {
                    margin-top: 30px;
                    padding: 30px;

                    .test-description {
                        padding: 0 40px 0 30px;

                        .text-desciption {
                            font-size: 16px;
                        }
                    }

                    .test-img img {
                        width: 200px;
                        height: 200px;
                        margin-left: 0px;
                    }
                }
            }

            button.carousel-control-next {
                margin-right: -30px;
            }

            button.carousel-control-prev {
                margin-left: -30px;
            }
        }
    }

    #digital-dreams {
        padding: 28px 20px 25px 20px;

        .digital-dreams-inner {
            .main-head {
                font-size: 33px;
            }

            .img {
                img {
                    width: 113%;
                }
            }

            .text-desciption {
                padding-left: 20px;
            }

            .btn {
                padding-left: 20px !important;
            }
        }

    }

    #industries {
        .all-industries-box {
            padding-top: 40px;

            .industries-box .box {
                height: 120px;
                width: 120px;
            }
        }

        .text-desc {
            padding-top: 10px;
        }
    }


    #digital-dreams {
        .digital-dreams-inner {
            .text-desciption {
                padding-left: 0px;
            }

            .btn {
                padding-left: 0px !important;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}