// upwork
#upwork {
    .upwork-inner {
        justify-content: space-between;

        .img {

            img {
                width: 200px;
            }
        }

        .rating {
            width: 100%;
            display: flex;
            padding-top: 14px;

            button {
                background-color: #108a01;
                border: none;
                color: #fff;
                padding: 10px 10px;
                border-radius: 7px;
                width: 100%;
                font-size: 18px;
                margin-right: 20px;
            }

            .rating-inner {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;


                .star {
                    img {
                        width: 30px;
                        padding-right: 5px;
                    }

                }

                p {
                    font-family: PlusJakartaSans-Bold;
                    text-transform: capitalize;
                    padding-left: 10px;
                }
            }


        }

        .icon-box {
            display: flex;
            gap: 30px;
            justify-content: space-between;
            padding-top: 20px;

            .icon-box-inner {
                display: flex;
                align-items: center;

                img {
                    width: 35px;
                }

                p {
                    font-family: PlusJakartaSans-Bold;
                    padding-left: 10px;
                }
            }


        }

        .text-description {
            padding-top: 12px;
            text-align: justify;

            .btn-grp {
                padding-top: 20px;
                display: flex;
                gap: 30px;
            }
        }

        .btn-grp {
            padding-top: 20px;
            display: flex;
            gap: 30px;

            .green-btn {
                background: linear-gradient(90deg, rgba(0, 119, 1, 1) 0%, rgba(0, 196, 0, 1) 59%);
            }
        }

        .singlelogo {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }
}


// fiver
#fiver {
    background-color: #000;
    padding: 50px 0;
    color: #fff;

    .upwork-inner {
        .rating button {
            background-color: #fff;
            border: none;
            color: #000;
            padding: 10px 10px;
            border-radius: 7px;
            width: 100%;
            font-size: 18px;
            margin-right: 20px;
        }
    }

    .upwork {
        .singlelogo {
            img {
                border-radius: 5px;
            }

        }

        .upwork-inner {
            .img {
                img {
                    width: 160px;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    #upwork {
        margin-top: 7px;

        .upwork-inner {
            .btn-grp {
                display: block;

                .btn {
                    margin-bottom: 10px;
                }
            }

            .text-description {
                padding-top: 0px;
            }

            .img {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .rating {
                display: block;

                .rating-inner {
                    width: 100%;
                    justify-content: flex-start;
                    padding-top: 15px;
                }

                button {
                    width: 100%;

                }
            }

            .icon-box {
                display: block;

                .icon-box-inner {
                    padding-bottom: 18px;

                    img {
                        width: 29px;
                    }
                }
            }
        }
    }

    #fiver {
        .text-description {
            padding-top: 15px !important;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width:992px) {
    #upwork {
        .upwork-inner {
            .singlelogo {
                padding-top: 20px;
                justify-content: start;

                img {
                    width: 50%;
                }
            }
        }
    }

    #fiver {
        #upwork {
            .upwork-inner {
                .singlelogo {
                    img {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}