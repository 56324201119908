.appDevelopment {
    #digital-marketing-services {
        .icon-box {
            height: 365px;
            margin-top: 25px;
        }
    }

    .help-and-support {
        .accordion-body {
            ol {
                padding-left: 15px;

                li {
                    list-style: decimal;
                }
            }
        }

        img {
            height: 500px !important;
        }
    }

    #how-it-works {
        .img {
            img {
                height: 425px;
            }
        }

        .work-list-icons {
            .work-list {
                span {
                    align-items: flex-start;

                    img {
                        width: 48px;
                    }

                    h2 {
                        font-size: 20px;
                        font-family: 'PlusJakartaSans-Bold';
                        padding-left: 10px;
                    }

                    p {
                        font-family: "PlusJakartaSans-Light";
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    #workProcess {
        .display-flex {
            .steps-box {
                height: 370px;
                padding: 20px;

                .count {
                    width: 40px;
                    height: 40px;

                    span {
                        font-size: 20px;
                    }
                }

                h4 {
                    padding-top: 20px;
                    font-size: 22px;
                }
            }
        }
    }

    #advantages {
        img {
            height: 570px;
        }

        .top-advantage-spacing {
            .icon-box {
                height: 219px;
            }

            .third-box,
            .fourth-box {
                height: 161px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .appDevelopment {
        #digital-marketing-services {

            .icon-box {
                margin-top: 0px;
                height: auto;
            }
        }

        #advantages {
            img {
                height: auto;
            }
        }

        .help-and-support {
            img {
                height: auto !important;
            }
        }

        #workProcess {
            padding-bottom: 40px;

            .display-flex {
                .steps-box {
                    height: auto;
                    padding: 30px;
                }
            }
        }
    }

    #how-it-works {
        .img {
            padding-right: 11px;
        }
    }

}

@media only screen and (min-width: 600px) and (max-width:992px) {
    .appDevelopment {
        #digital-marketing-services {

            .icon-box {
                margin-top: 0px;
                height: auto;
            }
        }

        #workProcess {
            .display-flex {
                .steps-box {
                    height: auto;
                    padding: 30px;
                }
            }
        }
    }

    #how-it-works {
        .img {
            padding-right: 11px;
        }
    }

}