#portfolio-tab {
    #custom-tabs {
        #website-development {
            img {
                height: 500px;
                border: 1px solid #ccc;
                object-fit: cover;
            }

            .top-spacing {
                padding-bottom: 20px;
            }
        }
    }
}


#website-development {
    .web-flex {
        width: 100%;
        gap: 20px;
        justify-content: space-between;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 77%);
            /* Adjust the background color and transparency */
            z-index: 1040;
            /* Make sure the overlay is behind the modal */
        }

        button {
            width: 32%;
            padding: 0;
            border: none;
            background-color: transparent;
        }

        .modal.show.d-block {
            display: block !important;
            z-index: 1050;
            /* Keep modal above the overlay */
        }

        .modal-dialog {
            max-width: 70%;

            .modal-content {
                border: none;
                background-color: transparent;

                .modal-header {
                    padding-bottom: 0;
                    padding-top: 0;
                    border-bottom: none;

                    button {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        span {
                            font-size: 38px;
                            color: #fff;
                        }
                    }

                    .modal-title {
                        display: none;
                    }
                }

                .modal-body {
                    img {
                        border: none !important;
                    }
                }
            }

            .modal-body {
                padding-top: 0;

                img {
                    height: auto !important;
                }
            }
        }
    }

}

#logosPage {
    .logo-box-inner {
        padding-top: 15px;

        .logo-box {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            margin: 9px 9px 9px 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 270px;

            img {
                width: 80%;
            }
        }
    }
}

#BannerPage {
    .logo-box-inner {
        padding-top: 30px;

        .logo-box {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            padding: 10px;
            border-radius: 5px;

        }

    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #website-development {
        .web-flex {
            width: auto;
            gap: 0px;
            padding: 16px;
            padding-top: 0;

            .modal-dialog {
                max-width: 100%;

                .modal-body {
                    img {
                        margin-bottom: 0 !important;
                    }
                }
            }

            button {
                width: 100%;
            }
        }
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) and (max-width:992px) {
    #website-development {
        .web-flex {
            button {
                width: 48%;

                img {
                    height: 400px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}




// #website-development {
//     .top-spacing {
//         margin-top: 20px;
//     }

//     .images {
//         display: flex;
//         flex-wrap: wrap;
//         // gap: 10px;

//         img.thumbnail {
//             // width: 200px;
//             height: 150px;
//             object-fit: cover;
//             cursor: pointer;
//             border-radius: 8px;
//             transition: transform 0.2s ease-in-out;

//             &:hover {
//                 transform: scale(1.05);
//             }
//         }
//     }

//     .modal {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         position: fixed;
//         z-index: 1000;
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         background-color: rgba(0, 0, 0, 0.8);
//         animation: fadeIn 0.3s ease-in-out;

//         .close {
//             position: absolute;
//             top: 20px;
//             right: 30px;
//             color: #fff;
//             font-size: 35px;
//             font-weight: bold;
//             cursor: pointer;

//             &:hover,
//             &:focus {
//                 color: #999;
//                 text-decoration: none;
//                 cursor: pointer;
//             }
//         }

//         .modal-content {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             background-color: transparent;

//             .modal-img {
//                 max-width: 80%;
//                 max-height: 80%;
//                 border-radius: 10px;
//                 height: auto !important;
//                 border:none !important;
//                 margin-bottom: 0px !important;
//             }

//             .modal-txt {
//                 margin-top: 15px;
//                 color: #fff;
//                 font-size: 18px;
//                 text-align: center;
//             }
//         }
//     }

//     @keyframes fadeIn {
//         from {
//             opacity: 0;
//         }

//         to {
//             opacity: 1;
//         }
//     }

//     @keyframes fadeOut {
//         from {
//             opacity: 1;
//         }

//         to {
//             opacity: 0;
//         }
//     }
// }
/* PortfolioTabs.scss */

// #website-development {
//     .top-spacing {
//         margin-top: 20px;
//     }

//     .main-head {
//         font-size: 2.5rem;
//         font-weight: bold;
//     }

//     .short-head {
//         font-size: 1.5rem;
//     }

//     .row {
//         display: flex;
//         flex-wrap: wrap;
//     }

//     .images {
//         display: flex;
//         flex-wrap: wrap;

//         .col-md-4 {
//             padding: 10px;
//         }

//         .thumbnail {
//             width: 100%;
//             cursor: pointer;
//             border-radius: 5px;
//             transition: transform 0.3s;
//         }

//         .thumbnail:hover {
//             transform: scale(1.05);
//         }
//     }

//     .modal {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         z-index: 1000;
//         opacity: 1;

//         &.appear {
//             animation: fadeIn 0.3s ease-in-out;
//         }

//         .modal-overlay {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             background-color: rgba(0, 0, 0, 0.8);
//             /* Dark semi-transparent background */
//             z-index: 1;
//         }

//         .modal-content {
//             position: relative;
//             z-index: 2;
//             background-color: transparent;
//             padding: 20px;
//             border-radius: 8px;
//             max-width: 90%;
//             max-height: 90%;
//             border: none;

//             .close {
//                 position: absolute;
//                 top: -30px;
//                 right: -64px;
//                 font-size: 45px;
//                 cursor: pointer;
//                 color: #fff;

//             }

//             .modal-img {
//                 max-width: 100%;
//                 max-height: 80vh;
//                 border-radius: 8px;
//                 border: none !important;
//             }
//         }
//     }

//     #website-development {
//         .top-spacing {
//             margin-top: 20px;
//         }

//         .main-head {
//             font-size: 2.5rem;
//             font-weight: bold;
//         }

//         .short-head {
//             font-size: 1.5rem;
//         }

//         .row {
//             display: flex;
//             flex-wrap: wrap;
//         }

//         .images {
//             display: flex;
//             flex-wrap: wrap;

//             .col-md-4 {
//                 padding: 10px;
//                 position: relative;
//                 /* Required for overlay positioning */

//                 .thumbnail {
//                     width: 100%;
//                     cursor: pointer;
//                     border-radius: 5px;
//                     transition: transform 0.3s, opacity 0.3s;
//                     display: block;
//                     position: relative;
//                     z-index: 1;
//                 }

//                 .overlay {
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     background-color: rgba(0, 0, 0, 0.5);
//                     /* Semi-transparent overlay */
//                     border-radius: 5px;
//                     opacity: 0;
//                     /* Initially hidden */
//                     transition: opacity 0.3s ease-in-out;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     z-index: 2;
//                 }

//                 .overlay-text {
//                     color: #fff;
//                     font-size: 18px;
//                     text-align: center;
//                 }

//                 &:hover .overlay {
//                     opacity: 1;
//                     /* Show overlay on hover */
//                 }

//                 &:hover .thumbnail {
//                     transform: scale(1.05);
//                     opacity: 0.7;
//                 }
//             }
//         }

//         .modal {
//             position: fixed;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             z-index: 1000;
//             opacity: 1;

//             &.appear {
//                 animation: fadeIn 0.3s ease-in-out;
//             }

//             .modal-overlay {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 background-color: rgba(0, 0, 0, 0.8);
//                 /* Dark semi-transparent background */
//                 z-index: 1;
//             }

//             .modal-content {
//                 position: relative;
//                 z-index: 2;
//                 background-color: transparent;
//                 padding: 20px;
//                 border-radius: 8px;
//                 max-width: 90%;
//                 max-height: 90%;
//                 border: none;

//                 .close {
//                     position: absolute;
//                     top: -30px;
//                     right: -64px;
//                     font-size: 45px;
//                     cursor: pointer;
//                     color: #fff;
//                 }

//                 .modal-img {
//                     max-width: 100%;
//                     max-height: 80vh;
//                     border-radius: 8px;
//                     border: none !important;
//                 }
//             }
//         }
//     }

//     @keyframes fadeIn {
//         from {
//             opacity: 0;
//         }

//         to {
//             opacity: 1;
//         }
//     }
// }
// #website-development {
//     position: relative;

//     .overlay {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background: rgba(0, 0, 0, 0.6); // Semi-transparent black
//         z-index: 1040; // Ensure it appears behind the modal but above other content
//     }

//     .modal-open-bg .modal {
//         z-index: 1050; // Ensure the modal appears above the overlay
//     }
// }