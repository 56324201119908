* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PlusJakartaSans-Medium;
}

// UNIVERSAL COLOR

$primary-red: #FD0002;
$secondary-red: #9A0000;
$primary-Blue: #173163;
$secondary-Blue: #1048B4;
$linear-gradient-red: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);

// UNIVERSAL CSS
@font-face {
  font-family: PlusJakartaSans-Bold;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf);
}

@font-face {
  font-family: PlusJakartaSans-ExtraBold;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf);
}

@font-face {
  font-family: PlusJakartaSans-ExtraLight;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Light;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Medium;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Regular;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf);
}

p {
  font-family: PlusJakartaSans-Light;
}

img {
  width: 100%;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

p,
ul {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}

a:focus-visible {
  outline: none;
}

li {
  list-style: none;
}

.btn {
  padding: 0 !important;
}

a:focus-visible {
  outline: none;
}

.icon-box-head {
  font-size: 22px;
  font-family: PlusJakartaSans-Bold;
  padding-top: 40px;
  padding-bottom: 9px;
}

input::placeholder {
  text-transform: capitalize;
  font-size: 14px;
}

#get-in-touch .get-in-touch-inner .input-flex textarea::placeholder {
  text-transform: capitalize;
  font-size: 13px;
}

// UNIVERSAL BUTTON START
.btn button {
  border: none;
  padding: 7px 7px 7px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(253, 0, 2);
  background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  transition: all 0.5s;
}

.btn button:hover {
  display: flex;
  flex-direction: row-reverse;
  transition: all 0.5s;
  padding: 7px 15px 7px 7px;
}

.btn button:hover span {
  margin-left: 0px;
  margin-right: 10px;
}

.btn button span {
  background-color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
}

.btn button svg {
  fill: #000;
  font-size: 19px;
}

.top-spacing {
  margin-top: 60px;
}


// SHORT-HEAD START
.short-head {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  position: relative;
  padding-left: 23px;
  display: inline-block;
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 3px;
    background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  }

}

// SHORT-HEAD END
// ----------------
// MAIN-HEAD START
.main-head {
  font-size: 45px;
  font-weight: 700;
  line-height: 3.6rem;
  text-transform: capitalize;
}

// MAIN-HEAD END
// ------------------
.text-desc {
  font-size: 16px;
}

.text-desc {
  font-size: 16px;
  position: relative;
  padding-left: 20px;
}

.text-desc::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  /* Equivalent to the previous border-left width */
  height: 77%;
  background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  border-radius: 2px;
  /* Adjust the value to your desired roundness */
}

.banner-head {
  position: relative;
  padding-left: 20px;
  font-size: 32px;
  text-transform: uppercase;
  font-family: 'PlusJakartaSans-ExtraBold';

}

.banner-head::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  /* Equivalent to the previous border-left width */
  height: 77%;
  background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  border-radius: 2px;
  /* Adjust the value to your desired roundness */
}

.text-desciption {
  font-size: 17px;
}

// UNIVERSAL BUTTON END
.btn-extra-space {
  margin-top: 20px;
}

.btn-extra-space button {
  padding: 7px 7px 7px 35px;
  transition: all 0.5s;

  span {
    margin-left: 20px;
  }

}

.btn-extra-space button:hover {
  padding: 7px 35px 7px 7px;
}

.btn-extra-space button:hover {
  padding: 7px 35px 7px 7px;
}

.btn-extra-space button:hover span {
  margin-right: 20px;
  margin-left: 0px;
}


// BTN GROUP
.all-services-box .btn button,
#about .btn button,
#portfolio .btn button,
#digital-dreams .btn button,
#get-in-touch .get-in-touch-inner .btn button,
div#upwork .btn button,
#custom-tabs .tab-content .btn button {
  padding: 7px 7px 7px 35px;
  transition: all 0.5s;
}

.all-services-box .btn button span,
#about .btn button span,
#portfolio .btn button span,
#digital-dreams .btn button span,
#get-in-touch .get-in-touch-inner .btn button span,
#custom-tabs .tab-content .btn button span {
  margin-left: 20px;
}

.all-services-box .btn button:hover,
#about .btn button:hover,
#portfolio .btn button:hover,
#digital-dreams .btn button:hover,
#get-in-touch .get-in-touch-inner .btn button:hover,
div#upwork .btn button:hover,
#custom-tabs .tab-content .btn button:hover {
  padding: 7px 35px 7px 7px;
}

.all-services-box .btn button:hover span,
#about .btn button:hover span,
#portfolio .btn button:hover span,
#digital-dreams .btn button:hover span,
#get-in-touch .get-in-touch-inner .btn button:hover span,
#custom-tabs .tab-content .btn button:hover span {
  margin-right: 20px;
  margin-left: 0px;
}

.red-gradient {
  font-family: PlusJakartaSans-Bold;
  background: linear-gradient(90deg, rgba(253, 0, 2, 1) 0%, rgba(154, 0, 0, 1) 80%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}



// ABOUT-BANNER
#about-banner {
  padding: 50px 0;

  .left-text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-desciption {
      font-weight: 600;
    }
  }

  .img {
    padding-left: 30px;

    img {
      border-radius: 10px;
      height: 300px;
      object-fit: cover;
      width: 100%;
    }
  }
}

// RESPOSSIVNESS
@media only screen and (max-width: 600px) {
  div#about-banner {
    padding-left: 10px;
    padding-right: 10px;

    .img {
      padding-left: 10px;
      padding-top: 20PX;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width:992px) {
  #about-banner {
    .main-head {
      font-size: 39px;
    }

    .img {
      padding-left: 10px;
    }
  }
}