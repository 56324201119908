#CaseStudyInner {
    .casestudyinner {
        padding: 50px 0;

        .img {
            img {
                border-radius: 20px;
                height: 450px;
                object-fit: cover;
            }
        }

        .left-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;

            .banner-head {
                font-size: 45px;
            }

            .text-desciption {
                font-family: PlusJakartaSans-Bold;
            }
        }
    }

    #case-information {
        background-color: #f5f5f5;
        padding: 30px 0;

        .case-information-top {
            padding-top: 30px;

            .icon-box {
                display: flex;
                border-right: 2px solid #ccc;
                width: 23%;
                margin-right: 20px;

                span {
                    svg {
                        font-size: 45px;
                        background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                        color: #fff;
                        padding: 6px;
                        border-radius: 50%;
                    }


                }

                .icon-box-text {
                    padding-left: 10px;

                    h4,
                    p {
                        text-transform: capitalize;
                    }

                    h4 {
                        font-family: PlusJakartaSans-Bold;
                        margin-bottom: 0;
                        font-size: 22px;
                    }

                    p {
                        font-size: 15px;
                    }
                }
            }

            .icon-box-last-child {
                border-right: none;
            }
        }
    }

    #personalize {
        padding: 50px 0;

        .img-grp {
            padding: 20px 0;

            img {
                border-radius: 12px;
                height: 380px;
                object-fit: cover;
            }
        }
    }

    #challenges {
        background-image: url(../../../Assests/Img/BlueBg.webp);
        padding: 60px 0;

        .img-side {
            padding-right: 30px;

            .main-head {
                color: #fff;
            }

            img {
                height: 280px;
                object-fit: cover;
                padding-top: 10px;
            }
        }

        .text-desciption {
            color: #fff;
        }

        .accordion {
            .accordion-item {
                background-color: transparent;
                border-bottom: 1px solid #cccccc5c;

                .accordion-button:not(.collapsed) {
                    background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    font-size: 18px;
                }

                .accordion-button:not(.collapsed)::after {
                    background-image: url(../../../Assests/Img/minus.svg);
                }

                .accordion-button::after {
                    background-image: url(../../../Assests/Img/add-faq.svg);
                    background-size: 1rem;
                }

                .accordion-body {
                    padding: 0 25px 20px 0;

                    p {
                        text-align: inherit;
                    }
                }

                .accordion-button:not(.collapsed)::after {
                    background-size: 2rem;
                }

                .accordion-button {
                    color: #fff;
                    background-color: transparent;
                    font-size: 18px;
                }
            }
        }
    }

    #enhancing-beauty {
        .left-text {
            min-height: 200px;
            overflow: auto;
            position: sticky;
            top: 5%;
            height: 100%;


            .text-desciption {
                text-align: justify;
            }

            .head-space {
                padding-top: 20px;
                font-size: 38px;
            }
        }

        .list-hover {
            padding-left: 0;
            padding-top: 10px;

            li {
                position: relative;
                text-transform: uppercase;
                font-size: 16px;
                padding-bottom: 15px;
                transition: all 0.5s;
                width: fit-content;

                &:hover {
                    transition: all 0.5s;
                    padding-left: 12px;
                    color: #DB0001;
                }
            }

            li::before {
                display: none;
                content: "";
                position: absolute;
                left: 0;
                top: 30%;
                transform: translateY(-50%);
                width: 10px;
                height: 3px;
                background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                transition: all 0.5s;
            }

            li:hover::before {
                display: block;
                transition: all 0.5s;
            }
        }

    }

    #work-we-do {
        overflow: hidden;

        .col-md-6 {
            padding: 0;
        }

        img {
            height: 500px;
            object-fit: cover;
            object-position: 100% 10%;
        }

        .black-bg {
            background-color: #000;
            color: #fff;
            padding: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .list-hover {
                padding-left: 0;
                padding-top: 10px;

                li {
                    position: relative;
                    text-transform: uppercase;
                    font-size: 16px;
                    padding-bottom: 15px;
                    transition: all 0.5s;
                    width: fit-content;

                    &:hover {
                        transition: all 0.5s;
                        padding-left: 12px;
                        color: #DB0001;
                    }
                }

                li::before {
                    display: none;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 30%;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 3px;
                    background: linear-gradient(90deg, rgb(253, 0, 2) 0%, rgb(154, 0, 0) 80%);
                    transition: all 0.5s;
                }

                li:hover::before {
                    display: block;
                    transition: all 0.5s;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    #CaseStudyInner {
        .casestudyinner {
            padding-left: 20px;
            padding-right: 20px;

            .row {
                flex-direction: column-reverse;

                .left-text {
                    padding-left: 10px;
                    padding-bottom: 20px;

                    .banner-head {
                        line-height: 3.3rem;
                    }
                }

                .img {
                    img {
                        height: 270px;
                    }
                }
            }
        }

        #case-information {
            .case-information-top {
                padding-top: 0;

                .icon-box {
                    width: 45%;
                    border-right: none;
                    padding-top: 20px;

                    span {
                        svg {
                            font-size: 36px;
                        }
                    }

                    .icon-box-text {
                        h4 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        #personalize {
            padding: 50px 20px;

            .img-grp {
                padding-bottom: 0;

                img {
                    margin-bottom: 20px;
                }
            }
        }

        #challenges {
            padding: 60px 8px;

            .img-side {
                padding-right: 10px;

                img {
                    margin-bottom: 10px;
                }
            }


        }

        #enhancing-beauty {
            padding-left: 15px;
            padding-right: 15px;

            .left-text {
                position: inherit;
                padding-bottom: 10px;
            }

            .img {
                height: 500px;
                overflow-x: scroll;
            }
        }

        #work-we-do {
            img {
                height: 300px;
            }

            .black-bg {
                padding: 40px;

                .main-head {
                    font-size: 28px;
                }

                .list-hover {
                    li {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 600px) and (max-width:768px) {
    #CaseStudyInner {
        .casestudyinner {
            .left-text {
                padding-left: 10px;
            }

            .img {
                img {
                    height: 335px;
                }
            }
        }

        #case-information {
            .case-information-top {
                padding-top: 0;

                .icon-box {
                    width: 45%;
                    border-right: none;
                    padding-top: 20px;

                    span {
                        svg {
                            font-size: 36px;
                        }
                    }

                    .icon-box-text {
                        h4 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        #work-we-do {
            img {
                height: 385px;
            }

            .black-bg {
                padding: 40px;

                .list-hover {
                    li {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}