#webDevelopment-Works {
    img {
        border-radius: 5px;
        height: 700px;
        object-fit: cover;
    }

    .icon-box {
        border: 1px solid #fd0002;
        border-radius: 8px;
        margin-bottom: 25px;
        padding: 15px 20px 20px;

        .number {
            color: #fd0002;
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 7px;
        }

        h3 {
            font-family: PlusJakartaSans-Bold;
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
    }
}